@import "melon/public/css/tree_grid.css";
:root {
  --dt-border-color: #d1d8dd;
  --dt-primary-color: rgb(82, 146, 247);
  --dt-light-bg: #f5f7fa;
  --dt-light-red: #FD8B8B;
  --dt-light-yellow: #fffce7;
  --dt-orange: rgb(255, 160, 10);
  --dt-text-color: #000000;
  --dt-text-light: #dfe2e5;
  --dt-spacer-1: 0.25rem;
  --dt-spacer-2: 0.5rem;
  --dt-spacer-3: 1rem;
  --dt-border-radius: 3px;
  --dt-cell-bg: #fff;
  --dt-focus-border-width: 2px;
  --dt-selection-highlight-color: #fffce7;
  --dt-selection-highlight-color: var(--dt-light-yellow);
  --dt-toast-message-border: none;
  --dt-header-cell-bg: #fff;
  --dt-header-cell-bg: var(--dt-cell-bg);
}

.datatable *, .datatable *::after, .datatable *::before {
  box-sizing: border-box;
}

.datatable {
  position: relative;
  overflow: hidden;
}

.dt-scrollable {
  height: 40vw;
  overflow: auto;
  border-top: 2px solid #d1d8dd;
  border-top: 2px solid var(--dt-border-color);
}

.dt-scrollable--highlight-all {
  background-color: #fffce7;
  background-color: var(--dt-selection-highlight-color);
}

.dt-scrollable__no-data {
  text-align: center;
  padding: 1rem;
  padding: var(--dt-spacer-3);
  border-left: 1px solid #d1d8dd;
  border-left: 1px solid var(--dt-border-color);
  border-right: 1px solid #d1d8dd;
  border-right: 1px solid var(--dt-border-color);
}

.dt-row {
  display: flex;
}

.dt-row--highlight .dt-cell {
  background-color: #fffce7;
  background-color: var(--dt-selection-highlight-color);
}

.dt-row--unhighlight .dt-cell {
  background-color: #fff;
  background-color: var(--dt-cell-bg);
}

.dt-row--hide {
  display: none;
}

.dt-row:last-child:not(.dt-row-filter) {
  border-bottom: 1px solid #d1d8dd;
  border-bottom: 1px solid var(--dt-border-color);
}

.dt-cell {
  border: 1px solid #d1d8dd;
  border: 1px solid var(--dt-border-color);
  border-bottom: none;
  border-right: none;
  position: relative;
  outline: none;
  padding: 0;
  background-color: #fff;
  background-color: var(--dt-cell-bg);
  color: #000000;
  color: var(--dt-text-color);
  /*
   Fix for firefox and Edge
   https://stackoverflow.com/a/16337203
   firefox paints td background over border
  */
  background-clip: padding-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.dt-cell__content {
  padding: 0.5rem;
  padding: var(--dt-spacer-2);
  border: 2px solid transparent;
  border: var(--dt-focus-border-width) solid transparent;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dt-cell__edit {
  display: none;
  padding: 0.5rem;
  padding: var(--dt-spacer-2);
  background-color: #fff;
  background-color: var(--dt-cell-bg);
  border: 2px solid rgb(255, 160, 10);
  border: var(--dt-focus-border-width) solid var(--dt-orange);
  z-index: 1;
  height: 100%;
}

.dt-cell__resize-handle {
  opacity: 0;
  position: absolute;
  right: -3px;
  top: 0;
  width: 5px;
  height: 100%;
  cursor: col-resize;
  z-index: 1;
}

.dt-cell--editing .dt-cell__content {
  display: none;
}

.dt-cell--editing .dt-cell__edit {
  display: block;
}

.dt-cell--focus .dt-cell__content {
  border-color: rgb(82, 146, 247);
  border-color: var(--dt-primary-color);
}

.dt-cell--highlight {
  background-color: #f5f7fa;
  background-color: var(--dt-light-bg);
}

.dt-cell--dragging {
  background-color: #f5f7fa;
  background-color: var(--dt-light-bg);
}

.dt-cell--header {
  background-color: #fff;
  background-color: var(--dt-header-cell-bg);
}

.dt-cell--header:last-child {
  border-right: 1px solid #d1d8dd;
  border-right: 1px solid var(--dt-border-color);
}

.dt-cell--header .dt-cell__content {
  padding-right: 1rem;
  padding-right: var(--dt-spacer-3);
  font-weight: bold;
}

.dt-cell--header:hover .dt-dropdown__toggle {
  opacity: 1;
}

.dt-cell--tree-close .icon-open {
  display: none;
}

.dt-cell--tree-close .icon-close {
  display: flex;
}

.dt-cell:last-child {
  border-right: 1px solid #d1d8dd;
  border-right: 1px solid var(--dt-border-color);
}

.datatable[dir=rtl] .dt-cell__resize-handle {
  right: unset;
  left: -3px;
}

.icon-open, .icon-close {
  width: 16px;
  height: 16px;
}

.icon-open {
  display: flex;
}

.icon-close {
  display: none;
}

.dt-dropdown {
  position: absolute;
  right: 10px;
  display: inline-flex;
  vertical-align: top;
  text-align: left;
  font-weight: normal;
  cursor: pointer;
}

.dt-dropdown__toggle {
  opacity: 0;
  background-color: #fff;
  background-color: var(--dt-header-cell-bg);
}

.dt-dropdown__list {
  position: fixed;
  min-width: 8rem;
  z-index: 1;
  cursor: pointer;
  background-color: #fff;
  background-color: var(--dt-cell-bg);
  border-radius: 3px;
  border-radius: var(--dt-border-radius);
  padding: 0.5rem 0;
  padding: var(--dt-spacer-2) 0;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.dt-dropdown__list-item {
  padding: 0.5rem 1rem;
  padding: var(--dt-spacer-2) var(--dt-spacer-3);
}

.dt-dropdown__list-item:hover {
  background-color: #f5f7fa;
  background-color: var(--dt-light-bg);
}

.dt-dropdown--active .dt-dropdown__list {
  display: block;
}

.dt-tree-node {
  display: flex;
  align-items: center;
  position: relative;
}

.dt-tree-node__toggle {
  display: inline-block;
  cursor: pointer;
  margin-right: 0.2rem;
}

.dt-toast {
  position: absolute;
  bottom: 1rem;
  bottom: var(--dt-spacer-3);
  left: 50%;
  transform: translateX(-50%);
}

.dt-toast__message {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.8);
  color: #dfe2e5;
  color: var(--dt-text-light);
  border-radius: 3px;
  border-radius: var(--dt-border-radius);
  padding: 0.5rem 1rem;
  padding: var(--dt-spacer-2) var(--dt-spacer-3);
  border: none;
  border: var(--dt-toast-message-border);
}

.dt-input {
  outline: none;
  width: 100%;
  border: none;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.dt-freeze {
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f5f7fa;
  background-color: var(--dt-light-bg);
  opacity: 0.5;
  font-size: 2em;
}

.dt-freeze__message {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.dt-paste-target {
  position: fixed;
  left: -999em;
}

body.dt-resize {
  cursor: col-resize;
}